import api from '@service/api'
import http from '@service/axios'
import { deleteCookie } from '@/common/cookie'

export default {
  /**
   *
   * 可以只传回调函数，也可以传个对象，包含params和callback
   * 1.function () {}
   *
   * 2.
   * {
   *  params:{},
   *  callback: ()=>{}
   * }
   */

  getShopInfoList({ commit, state }, paramsData) {
    console.log('paramsData: ', paramsData)
    let type = typeof paramsData,
      callback,
      params
    console.log('type: ', type)
    switch (type) {
      case 'function':
        callback = paramsData
        break
      case 'object':
        callback = paramsData.callback
        params = paramsData.params
        break
    }
    if (state.shopList?.length) {
      //如果店铺列表已存在别调接口了
      callback && callback(state.shopList)
      return
    }
    let merchantCode = localStorage.getItem('merchantCode')
    if (!merchantCode) {
      commit('resetState')
      localStorage.clear()
      deleteCookie(() => {
        window.location.href = '/login'
      })
      return
    }
    let data = {
      merchantCode,
      status: 1,
    }
    if (params) {
      data = {
        ...data,
        ...params,
      }
    }
    http({
      url: api.getNewShopInfoList,
      data,
      type: 'post',
      hasLoading: false,
      success: (res) => {
        let list = res?.data || res?.result?.list || []
        list.forEach((item) => {
          item.partyId = item.departmentId
          item.partyName = item.departmentName
          item.name = item.shopName
        })
        commit('setState', {
          shopList: list,
        })
        localStorage.setItem('shopList', JSON.stringify(list))
        callback && callback(list)
      },
    })
  },

  // 设置微应用程序列表
  SET_MICRO_APPLICATION_LIST({ state }, { key, value }) {
    state.microApplicationList.set(key, value)
  },
}

export function getPathPrefix(path, prefix = '') {
  if (!path) return
  const pathArray = String(path)
    .split('/')
    .filter((item) => item)
  const basePath = prefix + pathArray[0]
  return basePath
}
